// navigation targets
const mobileMenuTrigger = document.querySelector('button.mobile-trigger')
const navigationTag = document.querySelector('nav.header-navigation')
const buttonTags = document.querySelectorAll('button.nav-item-trigger')
const dropdownTags = document.querySelectorAll('ul.nav-children')
const mediaQuery = window.matchMedia("(max-width: 699px)")

// reusable dropdown functions
const handleDropdownOpen = (dropdownButton, height) => {
  const dropdownList = dropdownButton.nextElementSibling
  dropdownButton.classList.add('js-active')
  dropdownList.classList.add('js-active')
  // dropdownButton.ariaExpanded = 'true'
  // dropdownList.ariaExpanded = 'true'

  dropdownList.style.maxHeight = `${dropdownList.scrollHeight}px`
}

const handleDropdownClose = (dropdownButton) => {
  const dropdownList = dropdownButton.nextElementSibling
  dropdownButton.classList.remove('js-active')
  dropdownList.classList.remove('js-active')
  dropdownList.style.maxHeight = `0px`

  // dropdownButton.ariaExpanded = 'false'
  // dropdownList.ariaExpanded = 'false'
}

const handleAriaLabel = (tag, ariaExpanded) => {
  tag.ariaExpanded = ariaExpanded === true ? `true` : `false`
}

// handle menu on resize
let timer

const handleNavigationResize = () => {

  if (mediaQuery.matches) {

    const dropdownOpen = document.querySelector('ul.nav-children.js-active')

    if (dropdownOpen) {
      timer = setTimeout(() => {
        dropdownOpen.style.maxHeight = `${dropdownOpen.scrollHeight}px`
      }, 10)
    }

    if (dropdownOpen && !navigationTag.classList.contains('active')) {
      navigationTag.classList.add('active')
      $('.header-navigation').show()
    }

  }

}

// closing the menu with clicks and keys
const outsideClick = (e) => {

  let clicked = e.target

  if (!e.target.classList.contains('nav-item-trigger')) {

    if (!e.target.parentElement.matches('.nav-children')) {
      const button = document.querySelector('.nav-item-trigger.js-active')
      const dropdown = document.querySelector('ul.nav-children.js-active')
      handleDropdownClose(button)
      handleAriaLabel(button, false)
    }

  }

}

// mobile menu toggle
$('button.mobile-menu-trigger').click(() => {
  let menu = $('nav.header-navigation').toggleClass('active')
  if (menu.hasClass('active')) {
    menu.slideDown(200)
  } else {

    if (dropdownTags) {
      // remove active dropdownTags
      dropdownTags.forEach(tag => {
        tag.classList.remove('js-active')
        tag.style.maxHeight = `0px`
      })
  
      // remove active buttonTags
      buttonTags.forEach(tag => {
        tag.classList.remove('js-active')
        handleAriaLabel(tag, false)
      })
    }
    menu.slideUp(200)
  }
})

if (buttonTags && dropdownTags) {

  // loop button tags to handle dropdowns
  buttonTags.forEach(button => {

    button.addEventListener('click', (e) => {

      const currentButton = e.currentTarget
      const currentDropdown = currentButton.nextElementSibling

      if (currentDropdown.classList.contains('js-active')) {

        handleDropdownClose(currentButton)
        handleAriaLabel(currentButton, false)

      } else {

        dropdownTags.forEach(tag => {
          tag.classList.remove('js-active')
          tag.style.maxHeight = `0px`
        })

        buttonTags.forEach(tag => {
          tag.classList.remove('js-active')
          handleAriaLabel(tag, false)
        })

        handleDropdownOpen(currentButton, true)
        handleAriaLabel(currentButton, true)

      }

    })

  })

  // handle menu on resize
  mediaQuery.addEventListener('change', handleNavigationResize)
  handleNavigationResize(mediaQuery)

  // closing the menu with clicks and keys
  document.addEventListener('click', (e) => {

    if (document.querySelector('ul.nav-children.js-active')) {
      outsideClick(e)
    }

  })

  window.addEventListener('keyup', (e) => {

    if (document.querySelector('ul.nav-children.js-active') && e.key === 'Escape') {
      const button = document.querySelector('.nav-item-trigger.js-active')
      const dropdown = document.querySelector('ul.nav-children.js-active')
      handleDropdownClose(button)
      handleAriaLabel(button, false)
    }

  })



}

// pause transitions on resize
// https://css-tricks.com/stop-animations-during-window-resizing/
let resizeTimer

const handlePauseTransitions = () => {
  document.body.classList.add('pause-transitions')
  clearTimeout(resizeTimer)

  resizeTimer = setTimeout(() => {
    document.body.classList.remove('pause-transitions')
  }, 400)
}

window.addEventListener('resize', handlePauseTransitions, false)
handlePauseTransitions()